import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Вінілові платівки Zunixo
			</title>
			<meta name={"description"} content={"Де кожен оберт - це історія"} />
			<meta property={"og:title"} content={"Вінілові платівки Zunixo"} />
			<meta property={"og:description"} content={"Де кожен оберт - це історія"} />
			<meta property={"og:image"} content={"https://zunixo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zunixo.com/img/4373824355.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zunixo.com/img/4373824355.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zunixo.com/img/4373824355.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zunixo.com/img/4373824355.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zunixo.com/img/4373824355.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zunixo.com/img/4373824355.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Про нас
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Наша історія
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Zunixo Vinyls почався з простої ідеї: створити притулок для меломанів, де можна було б відсвяткувати та поділитися багатим, теплим звучанням вінілу. Те, що почалося зі скромної колекції класичних платівок, перетворилося на улюблене місце для ентузіастів і новачків. Наша подорож побудована на пристрасті до матеріальної форми музики, радості відкриття нових звуків та спільноти, яка формується навколо спільного музичного досвіду.
					</Text>
				</Box>
				<Image src="https://zunixo.com/img/3.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://zunixo.com/img/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Наша колекція
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Серцем Zunixo є наша різноманітна колекція вінілових платівок, яка постійно розширюється. Охоплюючи десятиліття і жанри, кожен альбом у нашому магазині був ретельно відібраний, щоб забезпечити таку ж унікальну колекцію, як і наші відвідувачі. Від культових альбомів, що визначили епохи, до маловідомих релізів, які чекають на нове відкриття, наші полиці - скарбниця для допитливих умів і досвідчених колекціонерів.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому варто обрати Zunixo?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Унікальний досвід
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Пориньте у тактильну насолоду від гортання вінілу, відчуваючи вагу історії та мистецтва у своїх руках.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Якість і турбота
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Ми робимо все можливе, щоб забезпечити якість наших записів, від ретельного оцінювання до належного зберігання, щоб забезпечити найкращі враження від прослуховування.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Поради щодо звуку
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Наші співробітники - не просто наймані працівники, вони є завзятими колекціонерами та шанувальниками музики, які готові поділитися своїми знаннями та рекомендаціями.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Дух спільноти
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Zunixo - це не просто магазин, це центр спільноти, де збираються любителі музики, щоб поділитися своєю пристрастю, історіями та відкриттями.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});